import { Status_Type } from "../web/constants/constants"

export const getDeviceStateColorDashboard = (state: string, t?:any) => {
  switch (state?.toLowerCase()) {
    case (t(Status_Type.WARNING)?.toLowerCase()):
      return '#F89406'
    case (t(Status_Type.ERROR)?.toLowerCase()):
      return '#EE0000'
    case (t(Status_Type.UNCERTAIN)?.toLowerCase()):
      return '#7954b8'
    case (t(Status_Type.UNMONITORED)?.toLowerCase()):
      return '#808B96'
    case (t(Status_Type.INFO)?.toLowerCase()):
      return '#55C1F6'
    default:
      return '#14AE23'
  }
}


export const getDeviceStateColor = (state: string) => {
  switch (state?.toLowerCase()) {
    case Status_Type.WARNING:
      return '#F89406'
    case Status_Type.ERROR :
      return '#EE0000'
    case Status_Type.UNCERTAIN:
      return '#7954b8'
    case Status_Type.UNMONITORED:
      return '#808B96'
    case Status_Type.INFO:
      return '#55C1F6'
    default:
      return '#14AE23'
  }
}