import React, {useEffect, useRef, useState} from "react";
import { Row, Col, Tooltip, Input, Button as AntdButton } from "antd";
import { DisplayViewType, HeaderType } from "../../../../../constants/enums";
import { Role } from "../../../../../models/common/role";
import {Dropdown} from "../../../../../../bit_components/common/dropdown";
import { AddUserImg, SearchImg } from "../../../../../images";
import { Location } from "../../../../../models/common/location";
import { useDispatch } from "react-redux";
import { setUserListViewType } from "../../../../../../redux/actions/user/userListAction";
import { UserFilterIcons, UserListHead } from "./index.styles";
import {Button as CustomButton} from "../../../../../../bit_components/common/button"
import { getAppHeaderType } from "../../../../../../utility/appUtil";
import { downButtonWhite } from '../../../../../images'
import { useTranslation } from 'react-i18next';
import { jsonData } from "../../../../../constants/constants";
import secureLocalStorage from "react-secure-storage";
import { SearchOutlined } from "@ant-design/icons";

export const UserListHeader = (props: any) => {
  const { t } = useTranslation()
  const {
    viewType,
    viewTypeChangeCallback,
    roles = [],
    locations = [],
    defaultBranch,
    defaultRole,
    addUserCallback,
    branchChangeCallback,
    roleChangeCallback,
    searchTerm,
    setSearchTerm,
    searchToggle,
    setSearchToggle,
    handleSearch
  } = props;

  const [branchName, setBranchName] = useState<any>(t(jsonData.AllLocations))
  const [roleName, setRoleName] = useState(t(jsonData.AllRoles))
  const [searchBox, setSearchBox] = useState(false);
  const [focusClass,setFocusClass] = useState<string>("");
  const inputRef = useRef(null);
  const dispatch = useDispatch();

  const onViewChange = (value: string) => {
    if (viewTypeChangeCallback) {
      viewTypeChangeCallback(value);
      dispatch(setUserListViewType(value))
    }
  };

  const onAddUser = () => {
    if (addUserCallback) {
      addUserCallback();
    }
  };

  useEffect(() => {
    if (searchBox) {
      inputRef.current.focus();
    }
  }, [searchBox]);

  const onBranchesChange = (e: any) => {
    //debugger;
    if (branchChangeCallback) {
      getBranchName(e)
      branchChangeCallback(e);
    }
  };

  const onRolesChange = (e: any) => {
    if (roleChangeCallback) {
      getRoleName(e)
      roleChangeCallback(e);
    }
  };

  const onChangeText = (event: any) => {
    if (!event.target.value?.length && searchToggle) {
      handleSearch(event.target.value);
      setSearchToggle(false);
      inputRef.current.focus();
    }
    setSearchTerm(event.target.value);
  };

  const onEnterSearch = (event: any) => {
    handleSearch(event.target.value);
    setSearchToggle(true);
    inputRef.current.blur();
  };

  const inputHaveFocus = () => {
    setFocusClass("eventSearchBar")
  }

  const getRoles = () => {
    let arrRole: any[] = [];
    if (roles && roles.length) {
      arrRole = arrRole.concat(
        roles.map((x: Role) => {
          return { text: x.name, value: x.id };
        })
      );
    }
    let rolesFinal = arrRole?.sort((a, b) => a.text.localeCompare(b.text))
    rolesFinal.splice(0,0,
    {
      text: t(jsonData.AllRoles),
      value: "",
    },)
    //debugger;
    return rolesFinal;
  };

  const getBranches = () => {
    let arrBranches: any[] = [];

    if (locations && locations.length) {
      arrBranches = arrBranches.concat(
        locations.map((x: Location) => {
          return { text: x.name, value: x.id };
        })
      );
    }

    let branchesFinal = arrBranches?.sort((a, b) => a.text.localeCompare(b.text))
    branchesFinal.splice(0,0, {
      text: t(jsonData.AllLocations),
      value: "",
    },)
    return branchesFinal;
  };

  const getBranchName = (val) => {
    if(val) {
      const name = locations.filter((data:any) => data.id === val)[0].name
      setBranchName(name)
    } else {
      setBranchName(t(jsonData.AllLocations))
    }
  }

  const getRoleName = (val) => {
    if(val) {
      const role = roles.filter((data:any) => data.id === val)[0].name
      setRoleName(role)
    } else {
      setRoleName(t(jsonData.AllRoles))
    }
  }

  const getViewTypes = () => {
    let arrViewTypes: any[] = [
      {
        text: t(jsonData.CardView),
        value: DisplayViewType.CARD_VIEW,
      },
      {
        text: t(jsonData.ListView),
        value: DisplayViewType.LIST_VIEW,
      },
    ];
    return arrViewTypes;
  };

  var administrationClaim:any = secureLocalStorage.getItem('administrationUrl')
  administrationClaim = secureLocalStorage.getItem('LCRole') === 'User' ? null : administrationClaim
  const userLink = () => {
    window.open(
      `${administrationClaim}/user`, "_blank");
  }

  const toggleSearchBox = (evt) => {
    setFocusClass("");
    if(!searchTerm?.length && searchToggle){
      handleSearch(evt.target.value)
      setSearchToggle(false)
    }
    !searchTerm && setSearchBox(prev => !prev)
  };

  const handleClick = (e: any, clearIcon: any) => {
    if (e?.keyCode === 13) {
      clearIcon.click();
    }
    clearIcon.removeEventListener("keyup", (e) => handleClick);
  };

  const handleClear = (e: any) => {
    let clearIcon: any = document.querySelector('.ant-input-clear-icon');
    if (e?.keyCode === 9) {
      clearIcon.setAttribute("tabIndex", "0");
      clearIcon.addEventListener("keyup", (e) => handleClick(e, clearIcon))
    }
  };

  return (
    <div className="pl-vw">

      <Row className="userFilterHed">
        <Col>
          <UserListHead>{t(jsonData.Users)}</UserListHead>
        </Col>
        {
          administrationClaim?.length > 5 ?
            <Col className="manageUserWrapper" onKeyPress={userLink}>
              <Tooltip overlayClassName="dashBoardTooltip" placement='bottom' title={t(jsonData.ManageUsers)} >
                <div>
              <CustomButton
                type="smallBlack fs-12" tabIndex={0} ariaLabel={t(jsonData.ManageUsers)} role={"button"} onClick={userLink}>
                {t(jsonData.ManageUsers)}
                <img className="manageUserImg" src={downButtonWhite} alt="downbuttonImg" />
              </CustomButton>
              </div>
              </Tooltip>
            </Col>
            :
            null
        }
        {/* {appUser && getAppHeaderType(appUser) === HeaderType.BIBLIOTHECA_PORTAL && appUser.locations[0].role.name === "Bibliotheca User" ? null:
        <Col>
          <div onClick={onAddUser}>
            <CustomButton type="actionButton">
              <img src={AddUserImg} className="addUserImg" alt="list view" />
              Add User
            </CustomButton>
          </div>
        </Col>} */}

        <UserFilterIcons span={24} lg={24} xl={16}>
          <Row className="userFilter">
            <span className="userFilterTab bibFilterPos">
              <Dropdown className="filterDropdown userLocationDropdown"
                defaultValue={defaultBranch}
                value={branchName}
                optionValue={getBranches()}
                onChange={onBranchesChange}
                bordered={false}
                optionClass="filterBox"
                tabIndex={0}
                ariaLabel={branchName}
              />
            </span>
            <span className="userFilterTab">
              <Dropdown className="filterDropdown userRolesDropdown"
                defaultValue={defaultRole}
                value={roleName}
                optionValue={getRoles()}
                onChange={onRolesChange}
                bordered={false}
                optionClass="filterBox"
                tabIndex={0}
                ariaLabel={roleName}
              />
            </span>
            <span className="userFilterTab">
              <Dropdown className="filterDropdown userViewDropdown"
                defaultValue={viewType}
                optionValue={getViewTypes()}
                onChange={onViewChange}
                bordered={false}
                optionClass="filterBox"
                tabIndex={0}
                ariaLabel={viewType === '1' ? t(jsonData.ListView) : t(jsonData.CardView)}
                value={viewType === "1" ? t(jsonData.ListView) : t(jsonData.CardView)}
              />
            </span>
            <span aria-label={t(jsonData.search)} role={"link"}>
              <AntdButton
                className="serOrgBtn eventSearIcon"
                onClick={toggleSearchBox}
                shape="circle"
                type="link"
                icon={<SearchOutlined />}
              />
              {/* <img src={searchIconImg}></img></Button> */}
              {!!searchBox ? (
                <Input
                  type="text"
                  ref={inputRef}
                  className={`${focusClass} tablesearchbar highlighter`}
                  onChange={onChangeText}
                  onBlur={toggleSearchBox}
                  value={searchTerm}
                  placeholder={t(jsonData.Search)}
                  bordered={true}
                  id="highlighter"
                  onPressEnter={onEnterSearch}
                  onFocus={inputHaveFocus}
                  onKeyDown={(e) => handleClear(e)}
                  allowClear
                />
              ) : null}
              {/* <img src={SearchImg} alt="search icon" /> */}
            </span>
          </Row>
        </UserFilterIcons>
      </Row>
    </div>
  );
};
