/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import OrganisationSummary from "../../../stateless/organisation/summary";
import BasicInfo from "./basicInfo";
import Subscriptions from "./subscriptions";

import { fetchOrganisationSummary } from "../../../../../redux/actions/organisation/fetchOrganisationDetails";
import { organisationDetailsSelector, clearState, clearSummary, clearOrganisation } from "../../../../../redux/reducers/organisation/organisationDetailsReducer";

import { Row, Col, Tabs, Tooltip } from "antd";
import { SubHead, PortalIconAdj } from "./oganisationDetails.styles";
import { PortalIconImg } from "../../../../images";
import { Breadcrumbs } from "../../../stateless/common/breadcrumbs";
import { AppRoutes, UserRole } from "../../../../constants/enums";
import { Pages, PortalType, PortalTypeKey, jsonData } from "../../../../constants/constants";

import { MainLayoutService } from "../../../../services/ui/mainLayoutService";
import { HeaderType } from "../../../../constants/enums";

import './index.less';
import { urlModification } from "../../../../../utility/appUtil";
import { onPage, sidebarNavigate } from "../../../../../redux/actions/app/appAction";
import { SideBarItems } from "../../../../constants/sideBarConstants";
import { appSelector } from '../../../../../redux/reducers/app/appReducers';
import Loader from "../../../stateless/common/spinner";
import { userStateSelector } from "../../../../../redux/reducers/user/userReducer";
import { Redirect } from "react-router-dom"
import Button from "../../../stateless/common/button";
import { useTranslation } from 'react-i18next';

export const OrganisationDetails = (props: any) => {
  const { t } = useTranslation()
  const { appUser } = useSelector(userStateSelector);
  const breadcrumbs = [
    {
      text: 'Organisations',
      href: AppRoutes.ORGANISATION_LIST
    }
  ];

  const { TabPane } = Tabs;
  const history = useHistory();
  
  const dispatch = useDispatch();
  const { formState, organisation,selectedOrganisationId } = useSelector(organisationDetailsSelector);
  const [index, setIndex] = useState<string>("1");
  const { selectedOrganisation } = useSelector(appSelector);
  // const selectedId : any = history.location.state;
  const [loading,setLoading] = useState(false);
  const [breadCrumbClick, setBredCrumClick] = useState<boolean>(false)
  const tabsRef = useRef(null);

  useEffect(() => {
    dispatch(onPage({onComponent : Pages.BIBLIOTHECA}));
    dispatch(sidebarNavigate(SideBarItems.ORGANISATIONS));
    MainLayoutService.renderHeader(HeaderType.BIBLIOTHECA_PORTAL);
    dispatch(fetchOrganisationSummary(selectedOrganisationId))
    return () => {
      dispatch(clearSummary());
      dispatch(clearOrganisation());
      dispatch(clearState());
    };
  }, []);

  useEffect(()=>{
    setLoading(false);
  },[index])

  const changeTab = (activeKey: string) => {
    setLoading(true);
    setIndex(activeKey);
  };

  const goToOrganisations = () => {
    history.push(urlModification(AppRoutes.ORGANISATION_LIST));
  };


  const breadcrumbClick  = () => {
    setBredCrumClick(true)
  }

  const elements = document?.querySelectorAll(".ant-tabs-tab-btn");
  if (elements) {
    elements.forEach((element) => {
    element.setAttribute("tabIndex", "0");
    });
    }

  return (
    <>
    {(appUser.locations[0].role.name !== UserRole.PLATFORM_ADMIN && appUser.locations[0].role.name !== UserRole.BIBLIOTHECA_USER) ?

      <Redirect to={urlModification(AppRoutes.USER_DASHBOARD)} />
      :
      <>
        <div className="pl-vw">
          <div className="orgDetailContainer">
            <Row>
              <Col aria-label={""} role={""} onKeyPress={breadcrumbClick}>
                <Breadcrumbs breadcrumbs={breadcrumbs} breadCrumbClick={breadCrumbClick} setBredCrumClick={setBredCrumClick}></Breadcrumbs>
              </Col>
            </Row>
            <Row>
              <Col span={14}>
                <SubHead>{organisation?.result?.shortName ? `${organisation?.result?.shortName}` : ' '}</SubHead>
              </Col>
            </Row>
          </div>
        </div>
        <Row>
          <Col span={24}>
            <Tabs className="orgTabContainer paddLeft loaderSkelton" activeKey={index} onChange={changeTab} >
              <Tabs.TabPane  tab={t(jsonData.Summary)} key="1" className="orgDetailsTab">
              <Loader loading={formState?.loading || loading}>
                <OrganisationSummary onChangeTab={changeTab} />
              </Loader>
              </Tabs.TabPane>
              <Tabs.TabPane tab={t(jsonData.BasicInfo)} key="2" className="orgDetailsTab OrgBasicInfo">
              <Loader loading={formState?.loading || loading}>
                <BasicInfo id={selectedOrganisationId} organisation={organisation} />
              </Loader>
              </Tabs.TabPane>
              {/* <TabPane tab="Subscription" key="3" className="orgDetailsTab">
                <Loader loading={formState?.loading || loading}>
                  <Subscriptions id={selectedOrganisationId} organisation={organisation} />
                </Loader>
              </TabPane> */}
            </Tabs>
          </Col>
        </Row>
      </>
    }</>
  );
};
