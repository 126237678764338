/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-concat */
import React, { useEffect, useState, useRef } from "react";
import {
  Row,
  Col,
  Tooltip,
  Input,
  Button,
  DatePicker,
  TimePicker,
} from "antd";
import { SearchOutlined } from "@ant-design/icons";
import "./styles.less";
import { optionValues } from "../eventData";
import {
  BackwardVectorDark,
  downloadIcon,
  ForwardVectorDark,
  insectIcon
} from "../../../../images";
import { Dropdown } from "../../../../../bit_components/common/dropdown";
import { useDispatch } from "react-redux";
import "../eventHeader/CustomAntdStyle.less";
import { useLocation, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { getExportCsvFile } from "../../../../../utility/utils";
import moment from "moment-timezone";
import { DefaultTime, KeyboardKeyCharCode, PickerFormat, TimePattern, jsonData } from "../../../../constants/constants";
import { appSelector } from "../../../../../redux/reducers/app/appReducers";
import { organisationDetailsSelector, clearState, clearOrganisation } from "../../../../../redux/reducers/organisation/organisationDetailsReducer";
import { cameFromOrganisation, getFormattedDate, translateOptions } from "../../../../../utility/appUtil";
import { fetchOrganisationbyID } from "../../../../../redux/actions/organisation/fetchOrganisationDetails";
import { userStateSelector } from "../../../../../redux/reducers/user/userReducer";
import { CustomEventHeader, EventHeaderStyle, DataDisplayed, EventHeadBack } from "./index.styles";
import { setShowEventLog, insightsSelector, showPrevReport, setEventDataValues } from "../../../../../redux/reducers/insights/insightsReducer";
import { AppRoutes } from "../../../../constants/enums";
import { daysDifference } from "../../../../../utility/insightUtils";
import { Messages } from "../../../../constants/messages";
import { AppError } from "../../../stateless/common/appError";
import {Button as CustomButton} from "../../../../../bit_components/common/button";
import '../../../stateless/common/button/button.less'
import { useTranslation } from 'react-i18next';
import { UseGAEventTracker } from "../../../../../utility/useGAEventTracker";
import {setEventLogIsRefresh } from "../../../../../redux/reducers/eventLogs/eventLogListReducer";
import secureLocalStorage from "react-secure-storage";

const EventLogsHeader = (props: any) => {
  const { selectedOrganisation } = useSelector(appSelector);
  const { organisation } = useSelector(organisationDetailsSelector);
  const { showEventLog, eventDataValues } = useSelector(insightsSelector)
  const history = useHistory();
  const { appUser } = useSelector(userStateSelector);
  const { routePathName } = useSelector(userStateSelector);
  let localeInfo:any = secureLocalStorage.getItem('locale')
  const { t } = useTranslation()
  const {downloading, setFiltersChanged, setSelectedDeviceStates, oldFilters, setFilterVal} = props


  //Date constants
  moment.tz.setDefault(localeInfo.timezone)
  const selectedDate = moment();
  const datePattern = "D MMMM, YYYY " + TimePattern.HH_MM

  const setMinDate = "21 July, 2015 12:08";
  const maxDateRange = moment().add(0, "day").format(datePattern);
  const minDaterange = moment(setMinDate).format(datePattern);
  const minDateVal = moment(minDaterange).format("x");
  const maxDateVal = moment(maxDateRange).format("x");


  /*  Header constant   */
  const [dayByDate, setDayByDate] = useState<any | null>();
  const [toDaybyDate, setToDaybyDate] = useState<any | null>();
  const [startTime, setStartTime] = useState<any | null>();
  const [endTime, setEndTime] = useState<any | null>();
  const [searchBox, setSearchBox] = useState(false);
  const [dropdownValue, SetDropdownValue] = useState("day");
  const [filterDuration, SetFilterDuration] = useState("Date Range");
  const [customFromDate, setCustomFromdate] = useState<any>(null);
  const [customToDate, setCustomTodate] = useState<any>(null);
  const [fromDate, setFromDate] = useState(null)
  const [toDate, setToDate] = useState(null)
  const [customFromTime, setCustomFromtime] = useState<any>(null);
  const [customEndTime, setCustomEndtime] = useState<any>(null);
  const [errorMsg, setErrorMsg] = useState(null)
  const [searchText, setSearchText] = useState<any | null>();
  const [editTimeFrame, setEditTimeFrame] = useState(false);
  const [customSelect, setCustomSelect] = useState(false);
  const [customtStartDate, setCustomtStartDate] = useState("");
  const [customtEndDate, setCustomtEndDate] = useState("");
  const [csvData, setCsvData] = useState("");
  const [searchTriggered, setSearchTriggered] = useState(false)
  const [disableUpdateButton, setDisableUpdateButton] = useState<boolean>(false)
  const customDateFormat = 'D MMMM, YYYY'
  const dateFormat = 'D MMMM, YYYY' + " " + PickerFormat.TIME
  const [exporting, setExporting] = useState<boolean>(false)
  const [focusClass,setFocusClass] = useState<string>("")

  const dispatch = useDispatch();
  //calculate date -----X------X----->
  const [dateCounter, updateDateCounter] = useState(0);
  //initialdate
  const [initialMoment, setInitialmoment] = useState<any | null>("day");
  //  const [dataReset, setDataReset] = useState<any>(false);
  //  const [onscroll, setOnscroll] = useState<any>(false);

  const getOrgId = () => {
    let orgId = appUser?.organization.id as string;
    if (cameFromOrganisation()) {
      orgId = selectedOrganisation?.id;
    }
    return orgId;
  }

  useEffect(() => {
    dispatch(fetchOrganisationbyID(getOrgId()))
    const node = document.querySelector<HTMLElement>(".ant-row .eventLogsHeader");
    const resizeObserver = new ResizeObserver((entries) => {
      props.onEventHeaderResize(node?.clientHeight)
    });
    resizeObserver.observe(node);
    return () => {
      dispatch(clearState());
      dispatch(clearOrganisation());
      dispatch(setShowEventLog(false))
      dispatch(setEventDataValues(null))
    };
  }, []);

  const calculateDate = () => {
    document.onkeydown = checkKey;
    let data =
    {
      endDate: selectedDate.add(dateCounter, initialMoment).format(datePattern),
      startDate: selectedDate.subtract(1, initialMoment).format(datePattern)
    }
    setDayByDate(data.startDate);
    setToDaybyDate(data.endDate);
    props.onSwitching();
    props.onSendCalculatedDate(data)
  };

  useEffect(() => {
    props?.csvData && setCsvData(props?.csvData)
  }, [props?.csvData]);

  useEffect(() => {
    setCsvData(props.filterCsv)
  }, [props.filterCsv]);

  useEffect(() => {
    initialMoment && calculateDate();
  }, [dateCounter, initialMoment]);
  const onClickDuration = () => {
    updateDateCounter(0);
  }
  //dropdown events
  const onChangeDuration = (value: string) => {
    if(window.location.pathname === AppRoutes.EVENT_LOGS){
      props?.clearLocationState();
    }    
    SetDropdownValue(value);
    setEditTimeFrame(false);
    setToDate(null)
    setFromDate(null)
    if (value === "week") {
      SetFilterDuration("Date Range");
      setCustomSelect(false);
      setInitialmoment("weeks");
      onClickDuration()
      UseGAEventTracker("Event Logs Category", "eventLogs Date Range", "week Selected" )
    } else if (value === "day") {
      SetFilterDuration("Date Range");
      setInitialmoment("day");
      setCustomSelect(false);
      onClickDuration()
      UseGAEventTracker("Event Logs Category", "eventLogs Date Range", "day Selected" )
    } else if (value === "month") {
      SetFilterDuration("Date Range");
      setInitialmoment("months");
      setCustomSelect(false);
      onClickDuration()
      UseGAEventTracker("Event Logs Category", "eventLogs Date Range", "month Selected" )
    } else if (value === "quarter") {
      SetFilterDuration("Date Range");
      setInitialmoment("Quarter");
      setCustomSelect(false);
      onClickDuration()
      UseGAEventTracker("Event Logs Category", "eventLogs Date Range", "quarter Selected" )
    } else if (value === "year") {
      SetFilterDuration("Date Range");
      setInitialmoment("Year");
      setCustomSelect(false);
      onClickDuration()
      UseGAEventTracker("Event Logs Category", "eventLogs Date Range", "year Selected" )
    } else {
      setCustomFromtime(null)
      setCustomEndtime(null)
      setCustomFromdate(null)
      setCustomTodate(null)
      setInitialmoment(null)
      setCustomtStartDate("")
      setCustomtEndDate("")
      SetFilterDuration("Custom Range");
      setCustomSelect(true);
      UseGAEventTracker("Event Logs Category", "eventLogs Date Range", "Custom date Selected" )
    }
  };

  const checkKey = (e) => {
    let searchBar = document.querySelector<HTMLElement>(`.tablesearchbar`)
    if (filterDuration !== "Custom Range" && !searchBar?.contains(e.target)) {
      e = e || window.event;
      if (e.keyCode === KeyboardKeyCharCode.LEFT_KEY) {
        if (moment(dayByDate).format("x") >= minDateVal) {
          updateDateCounter(dateCounter - 1);
        }
      } else if (e.keyCode === KeyboardKeyCharCode.RIGHt_KEY) {
        if (moment(toDaybyDate).format("x") <= maxDateVal) {
          updateDateCounter(dateCounter + 1);
        }
      }
    }
  };

  //next button logics
  const onNextbtn = () => {
    if(window.location.pathname === AppRoutes.EVENT_LOGS){
      props?.clearLocationState();
    } 
    updateDateCounter(dateCounter + 1);
    props.scrolltop(true)
    props.loading(true)
    UseGAEventTracker("Events Logs Category", "eventLogs Next Date Range", `Next ${dropdownValue} selected` )
  };
  //previous button logics
  const onPrevbtn = () => {
    if(window.location.pathname === AppRoutes.EVENT_LOGS){
      props?.clearLocationState();
    } 
    updateDateCounter(dateCounter - 1);
    props.scrolltop(true)
    props.loading(true)
    UseGAEventTracker("Events Logs Category", " eventLogs Previous Date Range", `Previous ${dropdownValue} selected` )
  };
  //custom date cal.
  const handleChangeStartDate = (evt: any, startdate: any) => {
    if (startdate && startdate.length) {
      setDisableUpdateButton(false)
      setFromDate(moment(startdate, localeInfo.momentFormat + " " + PickerFormat.TIME).format(dateFormat))
      setCustomFromtime(moment(startdate, localeInfo.momentDateTimeFormat).format(PickerFormat.TIME)) 
      setCustomFromdate(moment(startdate, localeInfo.momentFormat).format(customDateFormat))
      UseGAEventTracker("Event Logs Category", "eventLogs Start Custom Date", `Selected start date is: ${startdate}`)
    }
    else {
      setCustomFromdate(null)
      setFromDate(null)
      setCustomFromtime(null)
    }
  };
  const handleChangeEndDate = (evt: any, enddate: any) => {
    if (enddate && enddate.length) {
      setDisableUpdateButton(false)
      setToDate(moment(enddate, localeInfo.momentFormat + " " + PickerFormat.TIME).format(dateFormat))
      setCustomEndtime(moment(enddate, localeInfo.momentDateTimeFormat).format(PickerFormat.TIME)) 
      setCustomTodate(moment(enddate, localeInfo.momentFormat).format(customDateFormat))
      UseGAEventTracker("Event Logs Category", "eventLogs End Custom Date",  `Selected end date is: ${enddate})`)
    }
    else {
      setCustomTodate(null)
      setToDate(null)
      setCustomEndtime(null)
    }
  };

  // const handlestarttimepicker = (evt: any, starttime: any) => {
  //   starttime?.length ? setCustomFromtime(starttime) : setCustomFromtime(null)
  // };
  // const handleendtimepicker = (evt: any, endtime: any) => {
  //   endtime?.length ? setCustomEndtime(endtime) : setCustomEndtime(null)
  // };
  const checkInterval = (startDate: string, endDate: string) => {
    let numberOfDays = daysDifference(startDate, endDate) 
    switch (true) {
      case numberOfDays < 0:
        setErrorMsg(Messages.INVALID_RANGE)
        return false
      case isNaN(numberOfDays):
        customFromDate
          ? setErrorMsg(Messages.SELECT_END_DATE)
          : setErrorMsg(Messages.SELECT_START_DATE)
        return false
      default:
        setErrorMsg(null)
        return true
    }
  }
  const updateStates = (from: string, to: string, fromTime: string, toTime: string) => {
    let startTime = fromTime ?? DefaultTime.START
    let endTime = toTime ?? DefaultTime.END
    let data =
    {
      startDate: from + " " + startTime,
      endDate: to + " " + endTime,
    }
    if (checkInterval(data.startDate, data.endDate)) {
      let customfromDate: string
      let customtoDate: string
      !customFromTime && setCustomFromtime(DefaultTime.START)
      !customEndTime && setCustomEndtime(DefaultTime.END)
      setDayByDate(data.startDate);
      setToDaybyDate(data.endDate);
      setStartTime(fromTime)
      setEndTime(toTime)
      if (getFormattedDate(from, 'YYYY') !== getFormattedDate(to, 'YYYY')) {
        customfromDate = getFormattedDate(from, "D MMMM YYYY") + " " + startTime
        customtoDate = getFormattedDate(to, "D MMMM YYYY") + " " + endTime
      }
      else {
        customfromDate = getFormattedDate(from, "D MMMM") + " " + startTime 
        customtoDate = getFormattedDate(to, "D MMMM YYYY") + " " + endTime 
      }
      setCustomtStartDate(customfromDate);
      setCustomtEndDate(customtoDate);
      setEditTimeFrame(true);
      setCustomSelect(false);
      props.onSendCalculatedDate(data)
    }
  };
  //on click timeframe button
  const handletimeframe = () => {
    props.onSwitching();
    updateStates(customFromDate, customToDate, customFromTime, customEndTime)
    UseGAEventTracker("Event Logs Category", "eventLogs Updated Custom Date Range", `Selected custom date is: ${customFromDate} to ${customToDate} `)
  };

  //csv file name format
  const csvFileName =
    moment.utc(dayByDate).format("DMMMMYYYY," + TimePattern.HH_MM) +
    "_" +
    moment.utc(toDaybyDate).format("DMMMMYYYY," + TimePattern.HH_MM);
  const toggleSearchBox = (evt) => {
    if(window.location.pathname === AppRoutes.EVENT_LOGS){
      props?.clearLocationState();
    } 
    setFocusClass("")
    if(!searchText?.length && searchTriggered){
      props.handleSearch(evt.target.value)
      setSearchTriggered(false)
    }
      !searchText && setSearchBox(!searchBox)
  };
  const inputRef = useRef(null);

  useEffect(() => {
    if (searchBox) {
      inputRef.current.focus();
    }
    // setDataReset(searchBox)
  }, [searchBox]);

  const currentTime = moment().format("DD MMMM YYYY," +  TimePattern.HH_MM)
  const organizationName = organisation?.result?.name

  const titleDate =
    "Organisation:" +
    organizationName + " , " + "  " +
    "From:" +
    moment.utc(dayByDate).format("DD MMMM YYYY," + TimePattern.HH_MM) + " , " + "  " +
    "To:" +
    moment.utc(toDaybyDate).format("DD MMMM YYYY," + TimePattern.HH_MM) + " , " + "  " +
    "Exported at:" +
    currentTime;

  const onChangeText = (event: any) => {
    if(!event.target.value?.length && searchTriggered){
      props.handleSearch(event.target.value)
      setSearchTriggered(false)
      inputRef.current.focus()
    }  
    setSearchText(event.target.value)
  };
  const onEnterSearch = (event: any) => {
    props.handleSearch(event.target.value)
    setSearchTriggered(true)
    inputRef.current.blur()
  };

  const inputHaveFocus = () => {
    setFocusClass("eventSearchBar")
  }

  const handleClick = (e: any, clearIcon: any) => {
    if (e?.keyCode === 13) {
      clearIcon.click();
    }
    clearIcon.removeEventListener("keyup", (e) => handleClick);
  }

  const handleClear = (e: any) => {
    let clearIcon: any = document.querySelector('.ant-input-clear-icon');
    if (e?.keyCode === 9) {
      clearIcon.setAttribute("tabIndex", "0");
      clearIcon.addEventListener("keyup", (e) => handleClick(e, clearIcon))
    }
  }

  const showSearch = () => {
    if (!!searchBox) {
      return (
        <Input
          type="text"
          ref={inputRef}
          className={`${focusClass} tablesearchbar highlighter`}
          onChange={onChangeText}
          onBlur={toggleSearchBox}
          value={searchText}
          placeholder={t("Search")}
          bordered={true}
          id="highlighter"
          onPressEnter={onEnterSearch}
          onFocus={inputHaveFocus}
          onKeyDown={(e) => handleClear(e)}
          allowClear
        />
      );
    }
  };
  useEffect(() => {
    if (showEventLog && eventDataValues) {
      let { start, end } = eventDataValues
      let startTime = moment(start).format(TimePattern.HH_MM)
      let endTime = moment(end).format(TimePattern.HH_MM)
      let startFrom = moment(start).format('DD MMMM, YYYY')
      let endAt = moment(end).format('DD MMMM, YYYY')
      setCustomFromdate(startFrom)
      setCustomTodate(endAt)
      setCustomFromtime(startTime)
      setCustomEndtime(endTime)
      updateStates(startFrom, endAt, startTime, endTime)
      SetDropdownValue('custom')
      SetFilterDuration("Custom Range")
    }
  }, [showEventLog, eventDataValues]);

  // useEffect(() => {
  //   props.handleReset(dataReset);
  // }, [dataReset]);

  // useEffect(() => {
  //   setOnscroll(props.scrollstate)
  // }, [props.scrollstate]);

  const location: any = useLocation();
  const loc = location?.pathname

  const saveExcel = () => {
    if(window.location.pathname === AppRoutes.EVENT_LOGS){
      props?.onExport(organisation?.result?.name, csvFileName)
      UseGAEventTracker("Event Logs Category", "eventLogs export log file", "log file exported")
    }
    else{
      setExporting(true)
      getExportCsvFile(csvData, props.headers, titleDate, csvFileName).then(() => {
      setExporting(false)
      })
    }
  };

  const goToReportData = () => {
    dispatch(showPrevReport(true))
    history.goBack()
  }

  const isDisabled = () => {

    if (!props.filteredInfo) {
      return true
    }
    else if (props.filteredInfo.constructor === Object && (Object.keys(props.filteredInfo).length === 0 || Object.values(props.filteredInfo).every(o => o === null))){
      return true
    }
    else {  
      return false
    }
  }

  const refreshPage = () => {
    if(window.location.pathname === AppRoutes.EVENT_LOGS){
      props?.clearLocationState();
    } 
    if (dateCounter === 0) {
      if (routePathName === "/logs/eventLogs") {
        dispatch(setEventLogIsRefresh(true));
        setFiltersChanged(false);
        setSelectedDeviceStates(oldFilters)
        setFilterVal(oldFilters)
        calculateDate();
      }
      else {
        props.onRefresh();
      }
    }
    else {
      onChangeDuration(dropdownValue)
    }
  }

  return (
    <>
      <Row className="eventLogsHeader eventHeaderRow">
        <Col span={24} className="EventLogTitle">
          <Col style={{display:"flex", alignItems:"center"}}>
            <div className="EventLogTitle-heading">
              {window.location.pathname === AppRoutes.EVENT_LOGS && (
                <span>{t(jsonData.EventLogs)}</span>
              )}
              {window.location.pathname === AppRoutes.AUDIT_LOGS && (
                <span>{t(jsonData.AuditLog)}</span>
              )}
            </div>
            <div>
                {filterDuration === "Custom Range" ? (
                    ""
                  ) : (
                    <span className="leftrightarrow">
                      <Tooltip
                        overlayClassName="dashBoardTooltip"
                        placement="bottom"
                        title={t(jsonData.Previous)}
                      >
                        {moment(dayByDate).format("x") <= minDateVal ? (
                          <Button
                            aria-label={t(jsonData.decreaseDateRange)}
                            type="link"
                            size="small"
                            className="eventHeaderButton"
                          >
                            <img
                              className="eventHeaderButtonImg"
                              src={BackwardVectorDark}
                              alt=""
                            />
                          </Button>
                        ) : (
                          <Button
                            aria-label={t(jsonData.decreaseDateRange)}
                            type="link"
                            onClick={onPrevbtn}
                            size="small"
                            className="eventHeaderPrevious"
                          >
                            <img
                              className="eventHeaderPreviousImg"
                              src={BackwardVectorDark}
                              alt=""
                            />
                          </Button>
                        )}
                      </Tooltip>

                      <Tooltip
                        overlayClassName="dashBoardTooltip"
                        placement="bottom"
                        title={t(jsonData.Next)}
                      >
                        {moment(toDaybyDate).format("x") >= maxDateVal ? (
                          <Button
                            aria-label={t(jsonData.increaseDateRange)}
                            type="link"
                            size="small"
                            className="eventHeadPrev"
                          >
                            <img
                              className="eventHeadPrevImg"
                              src={ForwardVectorDark}
                              alt=""
                            />
                          </Button>
                        ) : (
                          <Button
                            aria-label={t(jsonData.increaseDateRange)}
                            type="link"
                            onClick={onNextbtn}
                            size="small"
                            className="eventHeadNext"
                          >
                            <img
                              className="eventHeadNextImg"
                              src={ForwardVectorDark}
                              alt=""
                            />
                          </Button>
                        )}
                      </Tooltip>
                    </span>
                  )}
            </div>
          </Col>

          {/* UI for filter other than custom date */}
          {filterDuration !== "Custom Range" && (            
            <Row gutter={[0, 10]}>
              {
                <Col className="edittimeframeCol">
                  {editTimeFrame === true ? (
                    <>
                      {/* eslint-disable-next-line */}
                      <a
                        onClick={() => {
                          setCustomSelect(true);
                          setEditTimeFrame(false);
                          setDisableUpdateButton(true);
                        }}
                        className="edittimeframe"
                      >
                        {t(jsonData.UpdateRange)}
                      </a>
                    </>
                  ) : (
                    filterDuration !== "Custom Range" && (
                      <Tooltip
                        overlayClassName="dashBoardTooltip"
                        placement="bottom"
                        title={t(jsonData.Refresh)}
                      >
                        <div>
                          <CustomButton
                            tabIndex={0}
                            type="actionButton"
                            onClick={refreshPage}
                            aria-label={""}
                            role={""}
                          >
                            {t(jsonData.Refresh)}
                          </CustomButton>
                        </div>
                      </Tooltip>
                    )
                  )}
                  {downloading || exporting ? (
                    <CustomButton
                      type="actionButton export-report"
                      onClick={saveExcel}
                    >
                      {/* <img src={downloadIcon} className="export-icon" alt="" />Export  */}
                      <span className="exportText">{t(jsonData.Export)}</span>
                      <div className="loaderButton">
                        <span className="spinner"></span>
                      </div>
                    </CustomButton>
                  ) : (
                    <Tooltip
                      overlayClassName="dashBoardTooltip"
                      placement="bottom"
                      title={t(jsonData.Export)}
                    >
                      <div>
                        <CustomButton
                          type="actionButton export-logs"
                          onClick={saveExcel}
                        >
                          {/* <img src={downloadIcon} className="eventDownIcon" alt="" /> */}
                          {t(jsonData.Export)}
                        </CustomButton>
                      </div>
                    </Tooltip>
                  )}
                  {!props.filtersChanged ? (
                    <>
                      {isDisabled() ? (
                        <></>
                      ) : (
                        <CustomButton
                          tabIndex={0}
                          onKeyPress={props.clearAllFilters}
                          type="filterBtn actionButton filter-button"
                          onClick={props.clearAllFilters}
                        >
                          {t(jsonData.ClearFilters)}
                        </CustomButton>
                      )}
                    </>
                  ) : (
                    <>
                      <CustomButton
                        tabIndex={0}
                        onKeyPress={() => props.applyFilter}
                        type="filterBtn actionButton applyButton filter-button"
                        onClick={props.applyFilter}
                      >
                        {t(jsonData.ApplyFilters)}
                      </CustomButton>
                    </>
                  )}
                </Col>
              }
            </Row>
          )}
        </Col>
        {
          filterDuration !== "Custom Range" &&
          <Col span={24}>
            <Row  className="eventLog-subHeader">
              <DataDisplayed>{props.dataDisplayed()}</DataDisplayed>
                <Row className="eventLogHeaderRight">
                  <Col >
                    <Row className="userFilter">
                      <span className="eventFilterTab">
                        <Dropdown
                          ariaLabel={dropdownValue}
                          className="filterDropDown eventDropdown"
                          defaultValue={"day"}
                          optionValue={translateOptions(optionValues,t,'text')}
                          onChange={onChangeDuration}
                          bordered={false}
                          value={dropdownValue}
                          optionClass="eventFilterBox"
                          tabIndex={0}
                        />
                      </span>
                    </Row>
                  </Col>
                  <Col className="orgSearchBtn eventSearchIcon">
                    <Tooltip overlayClassName='dashBoardTooltip' placement='bottom' className="eventSearchTooltip" title={t(jsonData.search)}>
                      <Button
                        className="serOrgBtn eventSearIcon"
                        onClick={toggleSearchBox}
                        shape="circle"
                        type="link"
                        icon={<SearchOutlined />}
                      />
                    </Tooltip>
                    {showSearch()}
                  </Col>
                </Row>
            </Row>
          </Col> 
        }
        {window.location.pathname === AppRoutes.EVENT_LOGS &&
          <EventHeadBack span={24} showevent={showEventLog.toString()}>
            <div className="backDiv">
              <span tabIndex={0} onKeyPress={goToReportData} onClick={goToReportData} className="backToPreviousPage">
                <img src={BackwardVectorDark} alt="" />
                {t(jsonData.Back)}
              </span>
            </div>
          </EventHeadBack>
        }

        {/* UI for custom date */}
        {filterDuration === "Custom Range" && (
        <Col span={24}>
          <Row gutter={[0,10]}>
            {filterDuration === "Custom Range" ? (
              ""
            ) : (
              
                <span className="leftrightarrow">
                  <Tooltip overlayClassName='dashBoardTooltip' placement="bottom" title={t(jsonData.Previous)}>
                  {moment(dayByDate).format("x") <= minDateVal ? (
                    <Button
                      aria-label={t(jsonData.decreaseDateRange)}
                      type="link"
                      size="small"
                      className="eventHeaderButton"
                    >
                      <img
                        className="eventHeaderButtonImg"
                        src={BackwardVectorDark}
                        alt=""
                      />
                    </Button>
                  ) : (
                    <Button
                    aria-label={t(jsonData.decreaseDateRange)}
                    type="link"
                    onClick={onPrevbtn}
                    size="small"
                    className="eventHeaderPrevious"
                    >
                      <img
                        className="eventHeaderPreviousImg"
                        src={BackwardVectorDark}
                        alt=""
                        />
                    </Button>
                  )}
                  </Tooltip>

                  <Tooltip overlayClassName='dashBoardTooltip' placement="bottom" title={t(jsonData.Next)}>
                  {moment(toDaybyDate).format("x") >= maxDateVal ? (
                    <Button
                    aria-label={t(jsonData.increaseDateRange)}
                      type="link"
                      size="small"
                      className="eventHeadPrev"
                    >
                      <img                        
                        className="eventHeadPrevImg"
                        src={ForwardVectorDark}
                        alt=""
                      />
                    </Button>
                  ) : (
                    <Button
                    aria-label={t(jsonData.increaseDateRange)}

                      type="link"
                      onClick={onNextbtn}
                      size="small"
                      className="eventHeadNext"
                    >
                      <img
                        className="eventHeadNextImg"
                        src={ForwardVectorDark}
                        alt=""
                      />
                    </Button>
                  )}
                   </Tooltip>
                </span>
            )}

            <Col className="eventHeading">
              <EventHeaderStyle>
                <b className="dateDuration">{t(filterDuration.replace(/\s/g, ''))}:</b>
                {filterDuration === "Custom Range" ? (
                  <span className="dateText">
                    {customtStartDate}
                    <span>-</span>
                    {customtEndDate}
                  </span>
                ) : (
                  <span className="dateText">
                    {filterDuration 
                      && getFormattedDate(dayByDate ,"D MMMM YYYY") +
                      " - " +
                      getFormattedDate(toDaybyDate ,"D MMMM YYYY")
                      }
                    {loc !== AppRoutes.EVENT_LOGS ? (
                      <span className="auditIcon">
                        <img src={insectIcon} alt="" />
                      </span>
                    ) : (
                      ""
                    )}
                  </span>
                )}
              </EventHeaderStyle>
            </Col>

            {
              <Col className="edittimeframeCol">
                {editTimeFrame === true ? (
                  <>
                    {/* eslint-disable-next-line */}
                    <a
                      onClick={() => {
                        setCustomSelect(true); setEditTimeFrame(false)
                        setDisableUpdateButton(true)
                      }}
                      className="edittimeframe"
                    >
                     { t(jsonData.UpdateRange)}
                    </a>
                    <Tooltip
                        overlayClassName="dashBoardTooltip"
                        placement="bottom"
                        title={t(jsonData.Refresh)}
                    >
                        <div>
                          <CustomButton
                            tabIndex={0}
                            type="actionButton"
                            onClick={props.onRefresh}
                            aria-label={""}
                            role={""}
                          >
                            {t(jsonData.Refresh)}
                          </CustomButton>
                        </div>
                    </Tooltip>
                  </>
                ) : (
                  filterDuration !== "Custom Range" &&
                  <Tooltip overlayClassName='dashBoardTooltip' placement="bottom" title={t(jsonData.Refresh)}>
                    <div>
                  <CustomButton tabIndex={0} type="actionButton" onClick={refreshPage} aria-label={""} role={""}>
                   {t(jsonData.Refresh)}
                  </CustomButton>
                  </div>
                  </Tooltip>
                )}
                { downloading || exporting ?                
                  <CustomButton type="actionButton export-report" onClick={saveExcel} >
                  {/* <img src={downloadIcon} className="export-icon" alt="" />Export  */}
                  <span className='exportText' >Export</span><div className="loaderButton"><span className="spinner"></span></div>
                </CustomButton> :
                <Tooltip overlayClassName='dashBoardTooltip' placement="bottom" title={t(jsonData.Export)}>
                <div>
                <CustomButton type="actionButton export-logs" onClick={saveExcel}>
                  {/* <img src={downloadIcon} className="eventDownIcon" alt="" /> */}
                  {t(jsonData.Export)}
                </CustomButton>
                </div>
                </Tooltip>
                }
                {!props.filtersChanged ?  
                  <>
                    {isDisabled() ? <></> :
                      <CustomButton tabIndex={0} onKeyPress={props.clearAllFilters} type="filterBtn actionButton filter-button" onClick={props.clearAllFilters}>
                        {t(jsonData.ClearFilters)}
                      </CustomButton>}
                  </>
                  :
                  <>
                    <CustomButton tabIndex={0} onKeyPress={() => props.applyFilter} type="filterBtn actionButton applyButton filter-button" onClick={props.applyFilter}>
                      {t(jsonData.ApplyFilters)}
                    </CustomButton>
                  </>
                }
              </Col>

            }
          </Row>
          <Row className="logHeader-2 eventLog-subHeader">
            <DataDisplayed>{props.dataDisplayed()}</DataDisplayed>
              <Row className="eventLogHeaderRight customBtn">
                <Col >
                  <Row className="userFilter">
                    <span className="eventFilterTab">
                      <Dropdown
                        ariaLabel={dropdownValue}
                        className="filterDropDown eventDropdown"
                        defaultValue={"day"}
                        optionValue={translateOptions(optionValues,t,'text')}
                        onChange={onChangeDuration}
                        bordered={false}
                        value={dropdownValue}
                        optionClass="eventFilterBox"
                        tabIndex={0}
                      />
                    </span>
                  </Row>
                </Col>
                <Col className="orgSearchBtn eventSearchIcon">
                  <Tooltip overlayClassName='dashBoardTooltip' placement='bottom' className="eventSearchTooltip" title={t(jsonData.search)}>
                    <Button
                      className="serOrgBtn eventSearIcon"
                      onClick={toggleSearchBox}
                      shape="circle"
                      type="link"
                      icon={<SearchOutlined />}
                    />
                  </Tooltip>
                  {showSearch()}
                </Col>
              </Row>
          </Row>
        </Col>
        )}             
      </Row>
      {customSelect === true ? (
        <>
          <hr className="hrBorder" />

          <Row className="customHeader marCustomHeader">
            <CustomEventHeader>
              {t(jsonData.From)}
            </CustomEventHeader>
            {/* <Col className="timePickerBox">
              <TimePicker
                placeholder={DefaultTime.START}
                onChange={handlestarttimepicker}
                format={PickerFormat.TIME}
                className="timePickerStyl"
                value={
                  customFromTime ? moment(customFromTime, PickerFormat.TIME) : null
                }
              />
            </Col> */}
            {/* <CustomEventHeader>
              on
            </CustomEventHeader> */}

            <Col className="datepickerBox">
              <DatePicker
                placeholder={t(jsonData['SelectDate&Time'])}
                showTime={{ format: 'HH:mm',defaultValue:moment('00:00', 'HH:mm')}}
                format={localeInfo.momentFormat + " " + PickerFormat.TIME}
                onChange={handleChangeStartDate}
                suffixIcon={null}
                className="datepicker"
                value={fromDate ? moment(fromDate, dateFormat) : null}
              />
            </Col>
            <CustomEventHeader>
              {t(jsonData.to)}
            </CustomEventHeader>
            {/* <Col className="timePickerBox">
              <TimePicker
                onChange={handleendtimepicker}
                placeholder={DefaultTime.END}
                format={PickerFormat.TIME}
                className="timePickerStyl"
                value={
                  customEndTime ? moment(customEndTime, PickerFormat.TIME) : null
                }
              />
            </Col> */}
            {/* <CustomEventHeader>
              on
            </CustomEventHeader> */}
            <Col className="datepickerBox">
              <DatePicker
                placeholder={t(jsonData['SelectDate&Time'])}
                showTime={{ format: 'HH:mm',defaultValue:moment('23:59', 'HH:mm')}}
                format={localeInfo.momentFormat + " " + PickerFormat.TIME}
                suffixIcon={null}
                onChange={handleChangeEndDate}
                className="datepicker"
                value={toDate ? moment(toDate, dateFormat) : null}
              />
            </Col>
            <Col>
              {!disableUpdateButton && 
                <Tooltip overlayClassName='dashBoardTooltip' placement="bottom" title={t(jsonData.UpdateRange)}>
              <Button
                type="primary"
                key="submit"
                htmlType="submit"
                onClick={handletimeframe}
                // disabled={disableUpdateButton}
                className="updateTimeFrameBtn"
              >
                {t(jsonData.UpdateRange)}
              </Button>
                </Tooltip>
              }
            </Col>
          </Row>
          <div className="pl-vw">
            <AppError show={errorMsg !== null} errorMsg={errorMsg} />
          </div>
          <hr className="hrBorder" />
        </>
      ) : null}
      <br />
    </>
  );
};
export default EventLogsHeader;
