import React, {useEffect, useRef, useState} from 'react';
import { Card, Row, Col, Button, Tooltip, Tabs,  } from 'antd';
import { ProfileThumbnailPicture } from '../user/profile/profileThumbnailPicture';
import { AvatarColorList, jsonData, Pages, PortalType, PortalTypeKey } from '../../../constants/constants';
import './organisationCard.less';
import { useHistory } from 'react-router';
import { AppRoutes, HeaderType } from '../../../constants/enums';
import { useDispatch, useSelector } from 'react-redux';
import { getSelectedOrg, onPage, sidebarNavigate } from '../../../../redux/actions/app/appAction';
import { clearOrganisation, clearState, clearSummary, organisationDetailsSelector, saveSelectedOrganisationId } from '../../../../redux/reducers/organisation/organisationDetailsReducer';
import { useTranslation } from 'react-i18next';
import { fetchOrganisationSummary } from '../../../../redux/actions/organisation/fetchOrganisationDetails';
import { SideBarItems } from '../../../constants/sideBarConstants';
import { MainLayoutService } from '../../../services/ui/mainLayoutService';
import {Button as CustomButton} from "../../../../bit_components/common/button";

const OrganisationCard = (props: any) => {
    const { t } = useTranslation()
    const history: any = useHistory();
    const dispatch: any = useDispatch();
    const cardRef = useRef(null);

    const goToPortal = () => {
        dispatch(saveSelectedOrganisationId(props.organisation.id));
        if (props.organisation.id !== '') {
          dispatch(getSelectedOrg(props.organisation.id));
        }
        const location = {
          state: { oid: props.organisation.id }
        };
        setTimeout(() => {
          history.push(location);
          const url = `${AppRoutes.USER_DASHBOARD}?${PortalTypeKey.CLIENT_PORTAL}=${PortalType.CLIENT_PORTAL}&oid=${props.organisation.id}`;
          const win = window.open(url, "_blank");
          if (win !== null) {
            win.focus();
          }
        }, 500);
      };
      
      

    const goToOrganisationDetails: any = () => {
        dispatch(saveSelectedOrganisationId(props.organisation.id ))
        const location = {
            pathname: AppRoutes.ORGANISATION_DETAILS,
            // search: '?oid=' + props.organisation.id,
            state: { oid: props.organisation.id }
        };
        props.organisation.id !=='' && dispatch(getSelectedOrg(props.organisation.id))
        history.push(location);
    };

    const handleKeyPress = (e: any) => {
        if (e.key === 'ArrowDown') {
            e.preventDefault();
            const nextCard = cardRef.current.nextSibling;
            if (nextCard) {
                nextCard.focus();
            }
        } else if (e.key === 'ArrowUp') {
            e.preventDefault();
            const prevCard = cardRef.current.previousSibling;
            if (prevCard) {
                prevCard.focus();
            }
        } else if(e.key === 'Enter') {
            goToPortal()
        }

    };
    const handleKeyEdit = (e:any) => {
        if (e.key === 'ArrowDown') {
            e.preventDefault();
            const nextCard = cardRef.current.nextSibling;
            if (nextCard) {
                nextCard.focus();
            }
        } else if (e.key === 'ArrowUp') {
            e.preventDefault();
            const prevCard = cardRef.current.previousSibling;
            if (prevCard) {
                prevCard.focus();
            }
        } else if(e.key === 'Enter') {
            e.stopPropagation();
           goToOrganisationDetails()
        }
    }

    useEffect(() => {
        const handleKeyDown = (e) => {
            if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
                e.preventDefault();
            }
        };

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    return (
        // <Link key={props.organisation.id} to={{ pathname: '/organisation/organisationDetails', state: { organisationId: props.organisation.id } }}>
        <div>
        <div onClick={goToPortal} className="organisationListWrapper" tabIndex={0} aria-label={`${t(jsonData.orgName)} ${props.organisation.name}`} role={"link"} onKeyDown={handleKeyPress} ref={cardRef}>
            <Card className="organisationListCard">
                <Row>
                    <Col span={3} lg={2} className="organisationLogoPic">
                        <ProfileThumbnailPicture src={props?.organisation?.logo} firstName={props.organisation.name.split(' ')?.[0] || ''} lastName={props.organisation.name.split(' ')?.[1] || ''} colors={AvatarColorList} round={true} size="35" />
                    </Col>
                    <Col span={6} lg={6} className="organisationShortName">
                        <div className="orgShortNameContentSlicing">{props.organisation.shortName}</div>
                    </Col>
                    <Col span={7} lg={7} className="organisationName">
                        <div className="orgNameContentSlicing">{props.organisation.name}</div>
                    </Col>
                    <Col span={4} lg={6} className="organisationCountry">
                        <div className="orgCountryContentSlicing">{props.organisation.county}, {props.organisation.country}</div>
                    </Col>
                    <Col className="editInfoBtn" onKeyDown={handleKeyEdit}>
                        <Tooltip placement="topRight" title={t(jsonData.Edit)}>
                            <CustomButton 
                                tabIndex={0} 
                                ariaLabel={t(jsonData.Edit)} 
                                role={"button"}
                                type="smallBlack fs-12" 
                                onClick={(e) => {
                                    e.stopPropagation();
                                    goToOrganisationDetails();
                                }}>
                                {t(jsonData.Edit)}
                            </CustomButton>
                        </Tooltip>
                    </Col>
                </Row>
            </Card>
        </div>
        </div>
        // </Link>
    );
};

export default OrganisationCard;
