/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "../appHeader/appHeader.less";
import { useDispatch, useSelector } from "react-redux";
import { Spin } from "antd";
import { postReadNotification, fetchTransformedNotifications } from "../../../../../redux/actions/notifications/notificationsAction";
import { notificationInput } from "../../../../../web/models/notification/notifications";
import { getFormattedString } from "../../../../../utility/utils";
import { NotificationSelector } from '../../../../../redux/reducers/notifications/notificationsReducer'
import { getElapsedTime } from "../../../../../utility/appUtil";
import { NotificationSpinner } from "../styledComponents/common.styles";
import { useTranslation } from 'react-i18next';
import { jsonData } from "../../../../constants/constants";
import { crossIcon } from "../../../../images/index";

const NotificationCards = (props: any) => {
  const { t } = useTranslation()
  const dispatch = useDispatch();
  const [notificationData, setNotificationData] = useState<any>([]);
  const [loading, setLoading] = useState<any>();
  const [exportNotificationLastRemove, setExportNotificationLastRemove] = useState<boolean>(false);
  const modalPopup = props.onModelOpen;
  const { exportNotifications, onUpdateExportNotification, onAddExportNotification, getUserDetails } = props;

  const parseData = (data: string) => {
    return JSON.parse(data);
  };

  const { formState } = useSelector(NotificationSelector);

  const getNotificationId = (input, field) => {
    var output = [];
    for (var i = 0; i < input?.length; ++i) output.push(input[i][field]);
    return output;
  };
  
  const isUrl = (content: string, data: any) => {
    const urlPattern = /^(https?:\/\/[^\s]+)$/g;
    if (content?.match(urlPattern)) {
      const existingNotification = exportNotifications.find(
        (notif) => notif?.id === data?.queueId
      );
      if (!existingNotification) {
        onAddExportNotification({
          id: data?.queueId,
          isDownloaded: false,
        });
      }
      return true;
    }
    return false;
  };

  var totalNotificationIds = getNotificationId(notificationData, "queueId");
  var totalOrganisationId = getNotificationId(
    notificationData,
    "organisationId"
  );
  var totalUserId = getNotificationId(notificationData, "userId");
  var totalLocationId = getNotificationId(notificationData, "locationId");

  let notificationInput: notificationInput = {
    readNotificationIds: totalNotificationIds,
  };
  if(totalUserId[0] !== null || undefined){
    notificationInput.userId = totalUserId[0];
  }
  if(totalOrganisationId[0] !== null || undefined){
    notificationInput.organisationId = totalOrganisationId[0];
  }
  if(totalLocationId[0] !== null || undefined){
    notificationInput.locationId= totalLocationId[0];
  }

  const getNotification = {
    userId: getUserDetails?.userId,
    organisationId: getUserDetails?.organisationId,
  };

  const removeExportNotification = (queueId) => {
    let params: any = { readNotificationIds: [queueId] }
    params.organisationId = getNotification?.organisationId
    params.userId = getNotification?.userId
    dispatch(postReadNotification(params))
    const updatedNotifications = notificationData.filter(
      (notif) => notif.queueId !== queueId
    );
    setNotificationData(updatedNotifications);
    let notifyArr = []
    updatedNotifications.map((notif) => {
      notifyArr.push(notif?.queueId)
    })
    params.readNotificationIds = notifyArr
    setTimeout(() => {
      dispatch(fetchTransformedNotifications(params));

    }, 1500);
  }

  useEffect(() => {
    setLoading(true);
    dispatch(fetchTransformedNotifications(getNotification));
  }, [modalPopup]);

  useEffect(() => {
    if (formState.isSuccess) {
      setLoading(false);
    }
    else if (formState.isError) {
      setLoading(false);
    }
    else {
      setLoading(false);
    }
  }, [formState]);

  useEffect(() => {
    setNotificationData(props.getNotificationData?.result);
  }, [props.getNotificationData]);

  useEffect(() => {
    props.ackParameters(notificationInput)
  }, [notificationData]);

  const newArray =
    notificationData !== undefined
      ? notificationData
      : [].map((elem) => ({
          id: elem.id,
          description: JSON.parse(elem.content),
          date: elem.createdAt,
          queueId: elem.queueId,
        }));


  // const isElementInViewport = (par, el) => {
  //   var elRect = el.getBoundingClientRect(),
  //     parRect = par.getBoundingClientRect();
  //   return (
  //     elRect.top >= parRect.top &&
  //     elRect.left >= parRect.left &&
  //     elRect.bottom <= parRect.bottom &&
  //     elRect.right <= parRect.right
  //   );
  // };

  return (
    <>
      <div
        id="bellBoundary"
        aria-label="Notifications"
        tabIndex={0}
      >
        <table>
          <tbody>
            {newArray.length >= 1 ? (
              newArray.map((data: any) => {
                const content = parseData(data?.content)?.description?.content;
                const params = parseData(data?.content)?.description?.params;
                let fileName = parseData(data?.content)?.metadata?.fileName;
                if (fileName) {
                  fileName = fileName.split('_');
                  fileName = fileName.slice(0, 2).join('_') + '.xlsx';
                }
                return (
                  <tr
                    id={data.queueId}
                    className={
                      !data.isRead ? "notificationSeen" : "notificatonNotseen"
                    }
                  >
                    <td>
                      {isUrl(content, data) ? (
                          <span className="fileNotification">
                            <a
                              href={content}
                              aria-label="Download file"
                              onClick={() =>
                                onUpdateExportNotification(data?.queueId)
                              }
                              tabIndex={0}
                              download
                              className="interactiveLink"
                            >
                              {fileName}
                            </a>
                            <button
                              className="remove-btn"
                              onClick={() =>
                                removeExportNotification(data?.queueId)
                              }
                            >
                              <img src={crossIcon} alt="Remove notification" />
                            </button>
                          </span>
                      ) : (
                        <span
                          className="notificationText"
                          aria-label={getFormattedString(content, params)}
                          tabIndex={0}
                        >
                          {getFormattedString(content, params)}
                        </span>
                      )}
                      <span className="notificationTime" aria-label={t(jsonData.NoNewNotifications)} tabIndex={0}>
                        {getElapsedTime(data?.createdAt)}
                      </span>
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                {loading ? (
                  <td>
                    <NotificationSpinner
                      className="spinner"
                    >
                      <Spin />
                    </NotificationSpinner>
                  </td>
                ) : (
                  <td>
                    <span className="notificationNotFound" aria-label={t(jsonData.NoNewNotifications)} tabIndex={0}>
                      {t(jsonData.NoNewNotifications)}
                    </span>
                  </td>
                )}
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default NotificationCards;
