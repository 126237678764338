import { useState, useEffect } from "react";
import { Col, Row, Tooltip, Form } from "antd";
import "./styles.less";
import {
  UnsavedChangesWarnModal,
  jsonData,
} from "../../../../../constants/constants";
import { Button as CustomButton } from "../../../../../../bit_components/common/button";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  DeviceSelector,
  clearChangedGroups,
  clearChangedItems,
  clearInvalidFields,
  clearNewlyAddedGroup,
  setRemovedGroups,
  setStoredSettings,
  clearReorderedGroups,
  clearNewlyAddedGroupIds,
  clearNewDeviceAssets,
  clearExistingDeviceAssets
} from "../../../../../../redux/reducers/device/deviceReducer";
import {
  getConfiguration,
  lockDeviceState,
  unLockDeviceState,
} from "../../../../../../redux/actions/device/deviceAction";
import { userStateSelector } from "../../../../../../redux/reducers/user/userReducer";
import WarnUnsavedChanges from "../../../common/warnUnsavedChanges";
import { appSelector } from "../../../../../../redux/reducers/app/appReducers";
import { useHistory } from "react-router";
import { urlModification } from "../../../../../../utility/appUtil";
import { warnUnsave } from "../../../../../../redux/actions/app/appAction";
import { AppService } from "../../../../../services/ui/appService";
import CopyModal, { DiscardModal, PendingChangesModal } from "../common/modals";
import SearchBar from "../common/searchBar";
import { clearCache } from "../../../../../../utility/utils";
import { storeWorkItem } from "../../../../../../redux/actions/workItems/workAction";

const DeviceConfigHeader = (props: any) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const { selectedDevice, applyChanges, goTo } = useSelector(appSelector);
  const {
    changedItems,
    isLocked,
    configState,
    copiedItems,
    invalidFields,
    previousConfigState,
    copiedGroups,
    changedGroups
  } = useSelector(DeviceSelector);
  const { appUser } = useSelector(userStateSelector);

  const {
    handleSearch,
    selectedSetting,
    getNodeContent,
    getConfigViews,
    isForbiddenStatus,
    callStatusApi,
    getDeviceAssets
  } = props;

  const [callLockApi, setCallLockApi] = useState(null);
  const [saveInit, setSaveInit] = useState(false);
  const [openSaveModal, setOpenSaveModal] = useState<boolean>(false);
  const [openCopyModal, setOpenCopyModal] = useState<boolean>(false);
  const [showDeviceList, setShowDeviceList] = useState<boolean>(false);
  const [unsaveOpened, setUnsaveOpened] = useState<boolean>(false);
  const [openDiscardModal, setOpenDiscardModal] = useState<boolean>(false);

  const deviceID = selectedDevice.lcDeviceId;

  const discardAll = () => {
    dispatch(clearChangedItems());
    dispatch(clearChangedGroups());
    dispatch(clearNewlyAddedGroup());
    dispatch(clearNewlyAddedGroupIds());
    dispatch(setRemovedGroups([]));
    dispatch(setStoredSettings({}));
    dispatch(clearInvalidFields());
    dispatch(clearReorderedGroups());
    dispatch(clearNewDeviceAssets());
    dispatch(clearExistingDeviceAssets());
    if (selectedSetting) {
      getNodeContent();
    }
  };

  const ApplyChanges = () => {
    setOpenSaveModal(true);
  };

  const fetchConfiguration = () => {
    dispatch(
      getConfiguration({
        userName: appUser?.userName,
        deviceId: selectedDevice?.lcDeviceId,
      })
    );
    setTimeout(() => {
      dispatch(
        storeWorkItem({
          userName: appUser?.userName,
          deviceIds: [selectedDevice?.lcDeviceId],
          userId: appUser?.id
        })
      );
      callStatusApi();
    },1500)
  };

  const handleCopy = () => {
    setOpenCopyModal(true);
  };

  const handleNavigate = () => {
    if(unsaveOpened){
      setTimeout(() => {
        setSaveInit(true);
        history.push(urlModification(goTo));
        dispatch(warnUnsave({ calledBy: null, isWarnUnsaveOpen: false }));
      }, 200);
    }
  };

  useEffect(() => {
    if ((changedItems && Object.keys(changedItems).length === 0) && (changedGroups && Object.keys(changedGroups).length === 0)) {
      setCallLockApi(false);
    } else {
      setCallLockApi(true);
    }
  }, [changedItems, changedGroups]);

  useEffect(() => {
    if ((copiedItems && Object.keys(copiedItems).length === 0) && (copiedGroups && Object.keys(copiedGroups).length === 0)) {
      setCallLockApi(false);
    } else {
      setCallLockApi(true);
    }
  }, [copiedItems,copiedGroups]);

  useEffect(() => {
    if (configState?.HasPermissionToEditConfiguration && callLockApi !== null) {
      if (callLockApi) {
        dispatch(
          lockDeviceState({
            userName: appUser?.userName,
            deviceId: selectedDevice?.lcDeviceId,
          })
        );
      } else {
        setTimeout(() => {
          dispatch(
            unLockDeviceState({
              userName: appUser?.userName,
              deviceId: selectedDevice?.lcDeviceId,
            })
          );
        }, 3000);
      }
    }
  }, [callLockApi]);

  useEffect(() => {
    if (applyChanges && goTo) {
      history.push(urlModification(goTo));
      dispatch(warnUnsave({ calledBy: null, isWarnUnsaveOpen: false }));
    }
  }, [applyChanges, goTo]);

  useEffect(() => {
    if (!openCopyModal || !openSaveModal) {
      setShowDeviceList(false);
    }
  }, [openCopyModal, openSaveModal]);

  useEffect(() => {
    if(previousConfigState?.ProcessingWorkItem && configState?.ProcessingWorkItem === false){
      clearCache("nodeMenus");
      clearCache("configViews");
      getConfigViews();
      getDeviceAssets();
    }
  }, [previousConfigState, configState]);

  return (
    <Row className="device-config-header">
      <WarnUnsavedChanges
        ignorePrompt={saveInit}
        navigateOnCancel={true}
        title={t(UnsavedChangesWarnModal.TITLE)}
        content={t(UnsavedChangesWarnModal.CONTENT)}
        cancelBtnText={t(UnsavedChangesWarnModal.DISCARD_BTN_TEXT)}
        confirmBtnText={t(UnsavedChangesWarnModal.CONFIRM_BTN_TEXT)}
        isDirty={changedItems && Object.keys(changedItems).length > 0 || changedGroups && Object.keys(changedGroups).length > 0}
        onConfirm={ApplyChanges}
        setUnsaveOpened={setUnsaveOpened}
      />
      <PendingChangesModal
        openSaveModal={openSaveModal}
        setOpenSaveModal={setOpenSaveModal}
        getNodeContent={getNodeContent}
        showDeviceList={showDeviceList}
        setShowDeviceList={setShowDeviceList}
        deviceID={deviceID}
        handleNavigate={handleNavigate}
        discardAll={discardAll}
        callStatusApi={callStatusApi}
        getConfigViews={getConfigViews}
        getDeviceAssets={getDeviceAssets}
      />
      <CopyModal
        openCopyModal={openCopyModal}
        setOpenCopyModal={setOpenCopyModal}
        copiedItems={copiedItems}
        copiedGroups={copiedGroups}
        showDeviceList={showDeviceList}
        setShowDeviceList={setShowDeviceList}
        deviceID={deviceID}
        handleNavigate={handleNavigate}
      />
      <DiscardModal
        openDiscardModal={openDiscardModal}
        setOpenDiscardModal={setOpenDiscardModal}
        discardAll={discardAll}
        getNodeContent={getNodeContent}
        deviceID={deviceID}
      />
      <Col className="DeviceConfigTitle">
        <Col>
          <div className="DeviceConfigTitle-heading">
            <span>{t(jsonData.Configuration)}</span>
          </div>
        </Col>

        <Row>
        <Col>
            <Tooltip
              mouseLeaveDelay={0}
              overlayClassName="dashBoardTooltip"
              placement="bottom"
              title={t(jsonData.GetConfiguration)}
            >
              <div className="get-configuration-button">
                <CustomButton
                  tabIndex={0}
                  type="actionButton"
                  disabled={
                    isLocked || isForbiddenStatus ||
                    configState?.HasPermissionToGetConfiguration === false ||
                    (changedItems && Object.keys(changedItems).length > 0) || (changedGroups && Object.keys(changedGroups).length > 0)
                  }
                  onClick={fetchConfiguration}
                  aria-label={""}
                  role={""}
                >
                  {t(jsonData.GetConfiguration)}
                </CustomButton>
              </div>
            </Tooltip>
          </Col>
          <Col>
            <Tooltip
              mouseLeaveDelay={0}
              overlayClassName="dashBoardTooltip"
              placement="bottom"
              title={t(jsonData.Copy)}
            >
              <div>
                <CustomButton
                  tabIndex={0}
                  type="actionButton"
                  onClick={handleCopy}
                  aria-label={""}
                  role={""}
                  disabled={
                    ((copiedItems && Object.keys(copiedItems).length === 0) &&
                    (copiedGroups && Object.keys(copiedGroups).length === 0)) ||
                    isLocked
                  }
                >
                  {t(jsonData.Copy)}
                </CustomButton>
              </div>
            </Tooltip>
          </Col>

          <Col>
            <Tooltip
              mouseLeaveDelay={0}
              overlayClassName="dashBoardTooltip"
              placement="bottom"
              title={t(jsonData.DiscardAll)}
            >
              <div className="discard-all-button">
                <CustomButton
                  tabIndex={0}
                  type="actionButton"
                  onClick={() => {setOpenDiscardModal(true)}}
                  aria-label={""}
                  disabled={
                    (changedItems && Object.keys(changedItems).length === 0 && 
                    changedGroups && Object.keys(changedGroups).length === 0 ) ||
                    isLocked || isForbiddenStatus
                   }
                  role={""}
                >
                  {t(jsonData.DiscardAll)}
                </CustomButton>
              </div>
            </Tooltip>
          </Col>

          
        </Row>
      </Col>

      <Col className="config-filter">
        <Row className="configuration-subHeader">
          <Col className="orgSearchBtn eventSearchIcon configSearchBtn">
            <SearchBar onSearch={handleSearch} />
          </Col>

          <Col className="config-apply-changes">
            <Form.Item>
              {false ? (
                <div className="config-saveBtn">
                  <CustomButton disabled type="smallBlack">
                    <div className="loaderButton">
                      <span className="spinner"></span>
                    </div>
                    {t(jsonData.Saving)}
                  </CustomButton>
                </div>
              ) : (
                <>
                  {changedItems && Object.keys(changedItems).length > 0 ||
                   changedGroups && Object.keys(changedGroups).length > 0 ? (
                    <Tooltip
                      mouseLeaveDelay={0}
                      overlayClassName="dashBoardTooltip"
                      placement="bottom"
                      title={t(jsonData.ApplyChanges)}
                    >
                      <div className="config-saveBtn">
                        <CustomButton
                          type="smallBlack"
                          onClick={ApplyChanges}
                          htmlType="submit"
                          disabled={isLocked || isForbiddenStatus || invalidFields?.length > 0}
                        >
                          {t(jsonData.ApplyChanges)}
                        </CustomButton>
                      </div>
                    </Tooltip>
                  ) : (
                    <div className="config-saveBtn">
                      <CustomButton
                        type="smallBlack"
                        disabled
                        htmlType="submit"
                      >
                        {t(jsonData.Saved)}
                      </CustomButton>
                    </div>
                  )}
                </>
              )}
            </Form.Item>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default DeviceConfigHeader;
