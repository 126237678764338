/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { headers, tablecolumn } from "./auditData";
import { map, get } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import "../eventLogs/eventHeader/styles.less";
import { auditLogListSelector } from "../../../../redux/reducers/auditLogs/auditLogListReducer";
import { fetchAuditLogTransformList } from "../../../../redux/actions/auditLog/auditListAction";
import "../eventLogs/eventHeader/CustomAntdStyle.less";
import EventLogsHeader from "../eventLogs/eventHeader/index";
import { cameFromOrganisation, getFormattedDate, getHeight, getOrganizationDateAndTime, urlModification } from "../../../../utility/appUtil";
import { userStateSelector } from "../../../../redux/reducers/user/userReducer";
import { appSelector } from "../../../../redux/reducers/app/appReducers";
import { clearAuditLogs,clearState } from "../../../../redux/reducers/auditLogs/auditLogListReducer";
import { onPage, sidebarNavigate } from "../../../../redux/actions/app/appAction";
import { SideBarItems } from "../../../constants/sideBarConstants";
import { AUDIT_DEFAULT_LIMIT, DEFAULT_PAGE, AUDIT_LOG_PAGE_SIZE, Pages, TimePattern, PickerFormat,jsonData, DevicePickerFormat, OrganisationDatePicker } from "../../../constants/constants";
import {getTableFilterData,getTableSortedData, sortList } from "../../../../utility/utils";
import Marker from "react-mark.js/Marker";
import Loader from "../../stateless/common/spinner";
import AntdPagination from "../../stateless/common/pagination/pagination";
import { AntdInfinityTable } from "../../stateless/common/antdTable/antdInfininityTable";
import { Redirect } from "react-router-dom"
import { AppRoutes, UserRole } from "../../../constants/enums";
import { useTranslation } from 'react-i18next';
import secureLocalStorage from "react-secure-storage";

export const AuditListContainer = (props: any) => {
  const dispatch = useDispatch();
  const { auditLogList, totalAudits, currentPage, totalPages, formState } = useSelector(auditLogListSelector);
  const [sendCalculatedDate, setSendCalculatedDate] = useState<any>({});
  //set table data between upto mindate or maxdate
  // const setMinDate = "21 July, 2015 12:08";
  //set datepicker and timepicker format
  /*  Header constant   */
  const [dayByDate, SetDayByDate] = useState<any | null>();
  const [toDaybyDate, SetToDaybyDate] = useState<any | null>();
  const [tableData, setTableData] = useState([] as any);
  const [filterTable, setFilterTable] = useState([] as any);
  const [searchFilterTable, setSearchFilterTable] = useState([] as any);
  const [filtercoumn, setFiltercoumn] = useState<any>();
  const [filterCsvData, setFilterCsvData] = useState([] as any);
  const [eventlogheadericon, setEventlogheadericon] = useState(null as any);
  const [loading, setLoading] = useState<any>(false);
  const { appUser } = useSelector(userStateSelector);
  const { selectedOrganisation } = useSelector(appSelector);
  const [scroll, setScroll] = useState<any>();
  const [filtersVal, setFiltersVal] = useState<any>();
  const [searchtext, setSearchtext] = useState<any>();
  const [filteredInfo, setFilteredInfo] = useState<any>(null);
  const [sortedInfo, setSortedInfo] = useState<any>(null);
  const [scrolledToEnd, setScrolledToEnd] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [scrollHeight, setScrollHeight] = useState(100);
  const screenHeight = document.body.clientHeight;
  const screenwidth = document.body.clientWidth;
  const [eventHeaderHeight, setEventHeaderHeight] = useState(54);
  const [oldFilters, setOldFilters] = useState({})
  const [filtersChanged, setFiltersChanged] = useState<boolean>(false)
  const [scrollValue, setScrollValue] = useState<number>(0)
  const [dataFetched, setDataFetched] = useState<boolean>(false)
  const [unfilteredData, setUnfilteredData] = useState([])
  const { t } = useTranslation()
  const [selectedDeviceStates, setSelectedDeviceStates] = useState<any>({});
  const [reset, setReset] = useState<boolean>(false);
  
  //Table
  useEffect(() => {
    dispatch(onPage({onComponent : Pages.SETTINGS}));
    dispatch(sidebarNavigate(SideBarItems.AUDIT_LOGS));
    setEventlogheadericon(true);
  }, []);
  const getOrgId = () => {
    let orgId = appUser?.organization.id as string;
    if (cameFromOrganisation()) {
      orgId = selectedOrganisation?.id;
    }
    return orgId;
  };


  const apiParams = {
    orgID: getOrgId(),
    startDate: dayByDate,
    endDate: toDaybyDate,
    limit:process.env.REACT_APP_AUDIT_LIMIT || AUDIT_DEFAULT_LIMIT,
  }

  useEffect(() => {
    return () => {
      dispatch(clearAuditLogs());
      dispatch(clearState());
    };
  }, []);

  useEffect(() => {
    if(formState.isSuccess === true){
      setLoading(false);
      dispatch(clearState());
    }
    else if(formState.isError === true){
      setLoading(false)
    }
  }, [formState]);

  useEffect(() => {
    dispatch(clearAuditLogs())
    setScrolledToEnd(false);
    callAuditLogApi(DEFAULT_PAGE)
  }, [dayByDate, toDaybyDate]);

  const callAuditLogApi = (page: number ) =>{    
   const evParam = {
      ...apiParams,
      currentPage: page
    };
      setLoading(true);
      dispatch(fetchAuditLogTransformList(evParam));
  }

  useEffect(() => {
    if (sendCalculatedDate !== undefined && sendCalculatedDate.constructor === Object && Object.keys(sendCalculatedDate).length) {
      SetDayByDate(sendCalculatedDate?.startDate);
      SetToDaybyDate(sendCalculatedDate?.endDate);
    }
  }, [sendCalculatedDate]);

  useEffect(() => {
    if(auditLogList?.length) {
      setScroll(true);
      // const slicedData = auditLogList.slice(currentIndex, currentIndex+AUDIT_LOG_PAGE_SIZE);
      tableInitialData(auditLogList).then((tableDataInitial:any)=>{
        setUnfilteredData(tableDataInitial)
        setTableData(tableDataInitial?.slice(currentIndex, currentIndex + AUDIT_LOG_PAGE_SIZE))
        setCurrentIndex(currentIndex+AUDIT_LOG_PAGE_SIZE)
      }).catch((err)=>{
        console.log('Failed to initialize table data while event logs got changed',err)
      });
    }
  }, [auditLogList]);

  useEffect(() => {
    filterTableData();
  }, [dayByDate, toDaybyDate, sendCalculatedDate]);

  useEffect(() => {
    filterTableData();
  }, [tableData]);

  //filter table  by dropdown userID
  function onlyUnique(value: any, index: any, self: any) {
    return self.indexOf(value) === index && (value !== null || '');
  }
  const listItems = auditLogList?.map((data: any) =>
    data?.userID
  );
  const uniqueLocation = listItems?.filter(onlyUnique);
  const userIdItem = uniqueLocation?.map((data: any) => {
    return {
      text: data,
      value: data,
    };
  }).sort(sortList('text'));
  //filter table  by dropdown Category
  const eventlistItems = auditLogList?.map(
    (data: any) => data?.category
  );
  const eventUniqueLocation = eventlistItems?.filter(onlyUnique);
  const categoryItem = eventUniqueLocation?.map((data: any) => {
    return {
      text: data,
      value: data,
    };
  }).sort(sortList('text'));
  //filter table  by dropdown event
  const eventtlistItems = auditLogList?.map(
    (data: any) => data?.event
  );
  const eventtuniquelocation = eventtlistItems?.filter(onlyUnique);
  const eventtitem = eventtuniquelocation?.map((data: any) => {
    return {
      text: data,
      value: data,
    };
  }).sort(sortList('text'));
  //filter table  by dropdown description
  const devicelistItems = auditLogList?.map(
    (data: any) => data?.content
  );
  const deviceuniquelocation = devicelistItems?.filter(onlyUnique);
  const descriptionItem = deviceuniquelocation?.map((data: any) => {
    return {
      text: data,
      value: data,
    };
  }).sort(sortList('text'));

  //set initial data
  const tableInitialData = async(data:any) => {
    let localeInfo:any = secureLocalStorage.getItem('locale');
    const dateFormat = (localeInfo.culture == 'en-US')
    ?  OrganisationDatePicker.DATE_TIME_US
    : OrganisationDatePicker.DATE_TIME_GB;
    const tableInitial = data?.map((data: any, index: any) => {
      return {
        key:index,
        id:data?.eventTimestamp,
        dateAndtime: getOrganizationDateAndTime(data?.eventTimestamp, dateFormat),
        datetime: data?.eventTimestamp,
        dateTimeStamp: data?.eventTimestamp,
        userID: data?.userID,
        category: data?.category,
        event: data?.event,
        content: data?.content,
      };
    })
    return tableInitial;
  };

  //filter data
  const filterTableData = () => {
    let data = tableData;
    setFilterTable(data);
    setSearchFilterTable(data);
  };

  const tableSorter = async(sorter: any, data: any) => {
    return getTableSortedData(sorter, data)
  }

  const sortData = (sorter) => {
    setScroll(true)
    if(!oldFilters || (Object.keys(oldFilters).length === 0 || Object.values(oldFilters).every(o => o === null))){
      tableSorter(sorter, unfilteredData).then((data)=>{
        setUnfilteredData(data)
        setTableData(data?.slice(0, AUDIT_LOG_PAGE_SIZE))
        setCurrentIndex(0 + AUDIT_LOG_PAGE_SIZE)
      })
    }
    else{
      tableSorter(sorter, filterTable).then((data)=>{
        setFilterTable(data)
      })
    }
  }

  const FilterChangeData =(filters:any)=>{
    let change = false
    if(Object.keys(oldFilters).length !== 0 || !Object.values(filters).every(o => o === null)){
      for(let val in filters){
        if((oldFilters[val] === undefined && filters[val] !== null) || 
          (oldFilters[val]?.toString() !== filters[val]?.toString())
        ){
          change = true
          break
        }
      }
    }
    if(change){
      setFiltersChanged(true)
    }
    else{
      setFiltersChanged(false)
    }
  }

  const filterSorting =(pagination, filters, sorter, extra)=>{
    sortData(sorter)
    setSortedInfo(sorter)
  }

  const replaceEmptyKey = (obj:any)=>{
    const inputObject = obj;
    // Iterate over the object's keys
    for (const key in inputObject) {
      if (Array.isArray(inputObject[key]) && inputObject[key].length === 0) {
        inputObject[key] = null;
      }
    }
    return inputObject
  }

  useEffect(() => {
    FilterChangeData(selectedDeviceStates)
    setFiltersVal(selectedDeviceStates)
    setFilteredInfo(selectedDeviceStates)
  }, [selectedDeviceStates]);

  const clearAllFilter = () => {
    setFilteredInfo(null)
    setSortedInfo(null)
    setFiltersVal(null)
    setOldFilters({})
    setFiltersChanged(false)
    setSelectedDeviceStates({})
  }
  
  const resetToDefault = () => {
    clearAllFilter()
    getTableFilterData({},unfilteredData,searchFilterTable).then((tableFilterData:any)=>{      
      setFilterTable(tableFilterData);
    }).catch((err)=>{
      console.log('Failed to get Filtered data ',err)
    })
  }

  const applyFilter = () => {
    setLoading(true)
    setOldFilters(filtersVal)
    setFiltersChanged(false)
    getTableFilterData(filtersVal,unfilteredData,searchFilterTable).then((tableFilterData:any)=>{
      setFilterTable(tableFilterData);
      setLoading(false)
    }).catch((err)=>{
      setLoading(false)
      console.log('Failed to get Filtered data ',err)
    })
  }
  
  useEffect(() => {
    clearAllFilter()
    }, [dayByDate,toDaybyDate]);

  //serachbar
  const searchUpdate = (search: any) => {
    setSearchtext(search)
    if (
      search.includes("[") === true ||
      search.includes("]") === true ||
      search.includes("(") === true ||
      search.includes(")") === true
    ) {
      return null;
    } else {
      const reg = new RegExp(search, "gi");
      const filteredData = map(searchFilterTable, (record: any) => {
        const datetimeMatch = get(record, "datetime")?.match(reg);
        const userMatch = get(record, "userID")?.match(reg);
        const typeMatch = get(record, "category")?.match(reg);
        const eventMatch = get(record, "event")?.match(reg);
        const contentMatch = get(record, "content")?.match(reg);
        if (
          !contentMatch &&
          !userMatch &&
          !typeMatch &&
          !eventMatch &&
          !datetimeMatch
        ) {
          return null;
        }
        return record;
      }).filter((record: any) => !!record);
      var data = search ? filteredData : searchFilterTable;
      setFilterTable(data);
      if(filteredInfo !== null && sortedInfo !== null){
        clearAllFilter()
      }
     else{
       return null
     }
    }
  };

  const selectedDeviceStateUpdate = (data: any, key: any,reset:any) => {
    let updatedDeviceStates = { ...selectedDeviceStates };
    setReset(reset)
    if (updatedDeviceStates?.hasOwnProperty(key)) {
      updatedDeviceStates = {...updatedDeviceStates,[`${key}`]:data[key]}
    } else {
      updatedDeviceStates = {...updatedDeviceStates,...data}
    }

    var replaceKeyVal = replaceEmptyKey(updatedDeviceStates)
    setSelectedDeviceStates(replaceKeyVal)
  };


  //table column
  useEffect(() => {
    setFiltercoumn(
      tablecolumn(userIdItem, descriptionItem, categoryItem, eventtitem,filteredInfo,sortedInfo,t,selectedDeviceStateUpdate,selectedDeviceStates)
    );
  }, [auditLogList,filteredInfo,sortedInfo]);
  
  const resetData = () => {
    // setFilterTable(searchFilterTable);
  };

  const scrolltotop =()=>{
    setScroll(true)
  }
 
  useEffect(() => {
   if(scroll){
     const node = document.querySelector<HTMLElement>(".table .ant-table-body");
     node?.scrollTo(0, 0);
   }
  }, [scroll]);

  const loadingProp = (data:any) => {
    if(data === true){
      setLoading(true);
    }
  };

  const onSendCalculatedDate = (data:any[]) => {
    setSendCalculatedDate(data);
  }

  const onScroll = (event:any) => {
    if (event.target.scrollTop && event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
      setScrolledToEnd(true);
    }
  }

  const fetchData = async(startIndex:number) => {
    const t = unfilteredData.slice(startIndex, startIndex+AUDIT_LOG_PAGE_SIZE);
    return t;
}

  const handleFetch = () => {
    if(!oldFilters || (Object.keys(oldFilters).length === 0 || Object.values(oldFilters).every(o => o === null))){
      if(tableData?.length !== unfilteredData?.length && scrolledToEnd){
        const node = document.querySelector<HTMLElement>(".table .ant-table-body");
        setScrollValue(node?.scrollTop)
        setScrolledToEnd(false);
        fetchData(currentIndex).then(async (newData:any) => {
          // const formattedData = await tableInitialData(newData);
          setTableData(tableData.concat(newData));
          setCurrentIndex(currentIndex+AUDIT_LOG_PAGE_SIZE);
          setDataFetched(true)
        }
      );
      }
    }
  }

  useEffect(() => {
    if(dataFetched){
      const node = document.querySelector<HTMLElement>(".table .ant-table-body");
      node?.scrollTo(0,scrollValue)
      setDataFetched(false)
    }
  },[dataFetched])

  const onSwitching=()=>{
    if(filteredInfo !== null && sortedInfo !== null){
      clearAllFilter()
    }
    setCurrentIndex(0)
    setTableData([])
    setUnfilteredData([])
    const node = document.querySelector<HTMLElement>(
      ".table .ant-table-body"
    );
    node?.scrollTo(0, 0);
  }

  const eventHeaderResized = (height:number) => {
    setEventHeaderHeight(height);
  }


  const csvFilterApplied = [filtersVal]?.map((data:any)=>{
    return {
    content: data?.content,
    event: data?.event,
    userID: data?.userID,
    category:data?.category,
    fromDate:getFormattedDate(dayByDate, "D MMMM, YYYY " + TimePattern.HH_MM),
    endDate:getFormattedDate(toDaybyDate, "D MMMM, YYYY " + TimePattern.HH_MM),
    searchFilter:searchtext
  }
    })
  
    let CsvTotalData = [...csvFilterApplied,...filterTable]

    if(!oldFilters || (Object.keys(oldFilters).length === 0 || Object.values(oldFilters).every(o => o === null))){
      CsvTotalData = [...csvFilterApplied,...unfilteredData]
    }
  
    // useEffect( () => {
    //   const tableHeader = document.querySelector<HTMLElement>(".ant-table-header")
    //   const appHeaderHeight = screenHeight*0.14 + 5
    //   const gap = 18;
    //   const compContentHeight = getHeight('.ant-layout-content')
    //   const bottomGap = compContentHeight - (screenHeight * 0.8)
    //   const pagination_div = document.querySelector('.audit-pagination')?.clientHeight
    //   const footerHeight = totalPages > 1 ? pagination_div + bottomGap : 0;
    //   const height = screenHeight- appHeaderHeight - eventHeaderHeight - gap - tableHeader?.clientHeight - footerHeight;
    //   const scrollH = parseInt(height.toFixed(0));
    //   !isNaN(scrollH) && setScrollHeight(scrollH)
    // },[screenHeight, screenwidth, loading, totalPages, eventHeaderHeight])


    useEffect(() => {
      //table height
      const tableHeight = getHeight('.ant-table-body')
  
      //to calculate table footer height
      const compContentHeight = getHeight('.ant-layout-content')
      const bottomGap = compContentHeight - (screenHeight * 0.8)
      const pagination_div = document.querySelector('.audit-pagination')?.clientHeight
      const footerHeight = totalPages > 1 ? pagination_div + bottomGap : 0;
  
      //to calculate final height of the table
      const calculatedHeight = tableHeight - footerHeight
      const scrollH = parseInt(calculatedHeight.toFixed(0));
      !isNaN(scrollH) && setScrollHeight(scrollH)
  
    }, [screenHeight, screenwidth, loading, totalPages, eventHeaderHeight])
  
    const dataDisplayed = () => {
      if(totalPages > 1){
        let limit = parseInt(process.env.REACT_APP_AUDIT_LIMIT) || AUDIT_DEFAULT_LIMIT
        let start = limit*(currentPage-1) + 1
        let end = limit*(currentPage-1) + auditLogList?.length 
        if(!oldFilters || (Object.keys(oldFilters).length === 0 || Object.values(oldFilters).every(o => o === null))){
          return `${start}-${end} ${t(jsonData.auditsShown)}`
        }
        else{
          return `${filterTable?.length} in ${start}-${end} ${t("audits shown")}`
        }
      }
      else{
        return `${totalAudits} ${t(jsonData.auditsShown)}`

      }
    }

    const onPageChange = (page: number) => {
      onSwitching()
      if(currentPage <= totalPages) {
        setScroll(false);
        callAuditLogApi(page)
      }
    }

    const onRefresh = () =>{
      clearAllFilter()
      setCurrentIndex(0)
      callAuditLogApi(DEFAULT_PAGE)
    }

  return (
    <>
    {appUser.locations[0].role.name === UserRole.LIBRARY_FRONT_DESK ?

      <Redirect to={urlModification(AppRoutes.USER_DASHBOARD)} />
      :
      <>
        <EventLogsHeader
          handleSearch={searchUpdate}
          csvData={CsvTotalData}
          filterCsv={filterCsvData}
          headers={headers}
          headerIcon={eventlogheadericon}
          handleReset={resetData}
          scrolltop={scrolltotop}
          scrollstate={scroll}
          loading={loadingProp}
          onSendCalculatedDate={onSendCalculatedDate}
          onSwitching={onSwitching}
          onEventHeaderResize={eventHeaderResized}
          clearAllFilters={resetToDefault}
          filteredInfo = {filteredInfo}
          dataDisplayed={dataDisplayed}
          applyFilter={applyFilter}
          filtersChanged={filtersChanged}
          onRefresh= {onRefresh}        
        />
        <Loader loading={loading}>
        <div className="pl-table">
          <Marker mark={searchtext}>
            <AntdInfinityTable
              tableClassName='auditCustomtable'
              // {...expandable}
              pagination={false}
              onScroll={onScroll}
              dataSource={filterTable}
              className={`table`}
              //@ts-ignore
              columns={filtercoumn}
              locale={!loading && !filterTable.length && { emptyText: (t(jsonData.NoDataFound)) }}
              onChange={(pagination, filters, sorter, extra) => {
                setFilterCsvData(extra.currentDataSource);
                filterSorting(pagination, filters, sorter, extra)
              }}
              rowKey="key"
              loading={loading}
              onFetch={handleFetch}
              pageSize={parseInt(process.env.REACT_APP_AUDIT_LIMIT) || AUDIT_DEFAULT_LIMIT}
              scroll={{ y: scrollHeight, scrollToFirstRowOnChange: true }}
              debug
            />
          </Marker>
          {
            totalPages > 1 &&
            <AntdPagination className='audit-pagination' total={totalAudits}  current={currentPage} defaultPageSize={parseInt(process.env.REACT_APP_AUDIT_LIMIT) || AUDIT_DEFAULT_LIMIT} onChange={(page: any, pageSize: any) => onPageChange(page)}/>
          }
          </div>
        </Loader>
      </>
    }
    </>
  );
};
